<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <h3 class="mb-2">Assignments</h3>

        <v-simple-table>
          <thead>
            <tr class="pb-4">
              <th>Vertical</th>
              <th>Country</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="i in items" :key="i.id">
              <td>{{i.vertical}}</td>
              <td>{{i.country}}</td>
              <td>
                <v-spacer></v-spacer>
                <v-btn icon @click="showEditItem(i.id, i.verticals_fk, i.countries_fk)">
                  <v-icon small>edit</v-icon>
                </v-btn>
                <v-btn icon @click="deleteItem(i.id)">
                  <v-icon small>delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </v-simple-table>

        <v-btn class="mt-3" color="primary" @click="showCreateItem()">Add</v-btn>

        <v-dialog v-model="showModal" persistent max-width="600px">
          <v-card>

            <v-card-title>
              <span class="headline">{{modalMode}} assignment</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <v-form ref="form">
                      <v-autocomplete v-model="selectedVertical" :items="activeVerticals" item-value="id" item-text="name" label="Vertical"
                       required :rules="[v => !!v || 'Vertical is required']"></v-autocomplete>
                      <v-autocomplete v-model="selectedCountry" :items="activeCountries" item-value="id" item-text="name" label="Country"></v-autocomplete>
                    </v-form>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeModal()">Close</v-btn>
              <v-btn color="blue darken-1" text @click="save()">Save</v-btn>
            </v-card-actions>

          </v-card>
        </v-dialog>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import formsMixin from '@/mixins/forms'

export default {
  name: 'AssignmentsByUser',

  props: {
    userID: { type: Number, required: true },
  },

  data () {
    return {
      items: [],
      item: {
        users_fk: null,
        verticals_fk: null,
        countries_fk: null,
      },
      valid: false,
      showModal: false,
      modalMode: null, // Create or Edit
      loading: false,
      selectedID: null,
      selectedVertical: null,
      selectedCountry: null,
      dataEndpoint: '/a/core/user-assignments',
    }
  },

  mixins: [formsMixin],

  computed: {
    activeCountries () {
      return this.$store.state.common.activeCountries
    },
    activeVerticals () {
      return this.$store.state.core.activeVerticals
    },
  },

  methods: {
    closeModal: function () {
      this.selectedID = null
      this.selectedVertical = null
      this.selectedCountry = null
      this.showModal = false
    },

    createItem: function () {
      // make sure all mandatory fields have a value
      if (!this.$refs.form.validate()) {
        return
      }
      this.fillItemFromSelection()

      var body = JSON.stringify(this.item)
      this.$http.post(this.dataEndpoint, body).then(resp => {
        this.fetchData()
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'POST', url: this.dataEndpoint, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.closeModal())
    },

    deleteItem: function (id) {
      if (!confirm('Are you sure?')) {
        return
      }

      var myURL = this.dataEndpoint + '/' + id

      this.$http.delete(myURL).then(resp => {
        this.fetchData()
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'DELETE', url: myURL, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
    },

    editItem: function () {
      // make sure all mandatory fields have a value
      if (!this.$refs.form.validate() || !this.selectedID) {
        return
      }
      this.fillItemFromSelection()

      var body = JSON.stringify(this.item)
      var myURL = this.dataEndpoint + '/' + this.selectedID
      this.$http.put(myURL, body).then(resp => {
        this.fetchData()
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'PUT', url: myURL, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.closeModal())
    },

    fetchData: function () {
      this.loading = true

      var myURL = this.dataEndpoint + '?xper_page=5000&xfields='
      // only get the fields needed to fill the item object
      myURL = this.forms_addItemFieldsToURI(myURL, this.item, ['id', 'user_name', 'vertical', 'country'])

      // filter for this user only
      myURL += '&users_fk=' + this.userID

      this.$http.get(myURL).then(resp => {
        this.items = resp.data.data
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'GET', url: myURL, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.loading = false)
    },

    fillItemFromSelection: function () {
      this.item['users_fk'] = this.userID
      this.item['verticals_fk'] = this.selectedVertical
      this.item['countries_fk'] = this.selectedCountry
    },

    save: function () {
      if (this.modalMode === 'Create') {
        this.createItem()
      } else {
        this.editItem()
      }
    },

    showEditItem: function (id, verticals_fk, countries_fk) {
      this.selectedID = id
      this.selectedVertical = verticals_fk
      this.selectedCountry = countries_fk
      this.modalMode = 'Edit'
      this.showModal = true
    },
    showCreateItem: function () {
      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
      
      this.modalMode = 'Create' 
      this.showModal = true
    }
  },

  created: function () {
    this.fetchData()
  },
}
</script>

<style scoped>
</style>